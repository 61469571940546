<template>
 <div>
        <vue-particles
                class="login-background"
                color="#97D0F2"
                :particleOpacity="0.7"
                :particlesNumber="50"
                shapeType="circle"
                :particleSize="4"
                linesColor="#97D0F2"
                :linesWidth="1"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="3"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push">
        </vue-particles>
        <div class="login-other">
            <el-form :rules="rules" ref="loginForm" :model="loginForm" class="loginContainer">
                <H3 class="loginTitle">管理后台</H3>
                <el-form-item prop="account">
                    <el-input prefix-icon="el-icon-user-solid" size="normal" type="text" v-model="loginForm.username"
                              auto-complete="off"
                              placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input prefix-icon="el-icon-lock" size="normal" type="password" v-model="loginForm.password"
                              auto-complete="off"
                              placeholder="请输入密码"></el-input>
                </el-form-item>
                
                <!-- <el-checkbox size="normal" class="loginRemember" v-model="checked">记住密码</el-checkbox> -->
                <el-button size="normal" type="primary" style="width: 100%;" @click="submitLogin">登录</el-button>
                
            </el-form>
        </div>
      
    </div>

</template>

<script>
import {login} from "../../network/login"

export default {
  data() {
    return {
      loginForm: {
       
        username: "",
        password: "",
       
      },
      checked: true,
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      
      },
    };
  },
  mounted() {
    

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },

  methods: {
    
   
  
     submitLogin() {
		//  this.$store.commit("LOGIN_IN", 111);
      this.$refs.loginForm.validate(async (valid) =>  {
          if (valid) {
          
          
            
          //  this.$store.commit("LOGIN_IN", 1);
          //             this.$router.push({
          //               path: "/home",
          //             })
          let res = await login(this.loginForm)
           console.log(res);
            if(res.code==200){
                    // this.$store.commit("LOGIN_IN", "123");
                     this.$store.commit("LOGIN_IN", res.data.access_token);
                      this.$router.push({
                        path: "/home",
                      })
                      .catch(() => {
                        //此处可以添加一个弹窗，例如：
                         alert('请先登录')
                      });
                    this.$message.success(res.msg);
                }else{
                   this.$message.error(res.msg);
                }
            
              
          } else {
              this.$message.error('请输入正确的登录信息');
              return false;
          }
      });
    },
  },
};
</script>

<style>
    #v_container{
      height:40px
    }
    .login-background {
          /* background: linear-gradient(-180deg, #c5dcf0 0%, #ffffff 100%); */
          
          background-image: linear-gradient(-180deg, #1a1454 0%, #0e81a5 100%);
          width: 100%;
          height: 100%; /**宽高100%是为了图片铺满屏幕 */
          z-index: -1;
          position: absolute;
      }

    .login-other {
        z-index: 1;
        margin: 180px 0 0 calc(calc(100vw - 410px) / 2);
        position: absolute;
    }

    .loginContainer {
        border-radius: 15px;
        background-clip: padding-box;
        width: 350px;
        padding: 15px 35px 15px 35px;
        background: #fefefe;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 5px #cac6c6;
    }

    .loginTitle {
        margin: 15px auto 20px auto;
        text-align: center;
        color: #707070;
    }

    .loginRemember {
        text-align: left;
        margin: 0 0 15px 0;
    }
</style>